<template>
    <div class="viewActivity viewActivityChat">
        <div class="top-right">
            <div class="act_mini_btn" @click="backActivity()">
                <span class="om-chev-left" style="margin-right:5px"></span> Back
            </div>
        </div>
        <div class="list_activities">
            <ActivityCommentComponent
            :data_comment="data_comment.comment"
            :data_user="data_user"
            :no_reply="'hide'"
            />
            <div class="hrChat" v-if="data_comment.comment.chat_number > 0">
                {{data_comment.comment.chat_number}} Reply
                <hr>
            </div>
            <ActivityCommentComponent
            :data_comment="item"
            :data_user="data_user"
            :no_reply="'hide'"
            v-for="(item,key) in data_comment.comment.chat" :key="key"
            />
        </div>
        <div class="bottom-right">
            <CommentComponent
            :data_task="data_task"
            :list_parent="data_comment.comment"
            :type_comment="'comment_parent'"
            @update-parent="updateParent"
            />
        </div>
    </div>
</template>
<script>

    import CommentComponent from '@/components/input/CommentComponent.vue';
    import ActivityCommentComponent from '@/components/space/comment/ActivityCommentComponent.vue';
    export default {
        name:"ActivityList",
        props: {
            data_comment: Object,
            data_task: Object,
            data_user: Array
        },
        components: {
            CommentComponent,
            ActivityCommentComponent
        },
        data: function() {
            return {
                muListActivity: this.data_activity,
                last_activity:{},
                show_Activity:false,
            };
        },
        methods: {
            updateParent(){
            },
            backActivity(){
                this.$emit('back-activity');
            },
        },
        mounted(){
        },
        watch: {
            'data_activity.list'(){
                var thos = this;
                setTimeout(function() {
                    thos.last_activity = (thos.data_activity.list.length > 0?thos.data_activity.list[thos.data_activity.list.length-1]:{});
                }, 10);
            }
        },
    };
</script>