<template>
    <div class="modal-body">
        <div class="list-priority flex" @click="setPriority(4)">
            <span class="om-flags-bold color-urgent"></span>
            <span>Urgent</span>
        </div>
        <div class="list-priority flex" @click="setPriority(3)">
            <span class="om-flags-bold color-high"></span>
            <span>High</span>
        </div>
        <div class="list-priority flex" @click="setPriority(2)">
            <span class="om-flags-bold color-normal"></span>
            <span>Normal</span>
        </div>
        <div class="list-priority flex" @click="setPriority(1)">
            <span class="om-flags-bold color-low"></span>
            <span>Low</span>
        </div>
    </div>
    <div class="modal-footer">
        <div class="list-priority flex" @click="setPriority()">
            <span class="om-traffic-signal"></span>
            <span>Clear</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
            };
        },
        methods: {
            save_update_task(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'task/update',{idTask:this.muParams.code,priority:this.muParams.priority,type:'priority'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.$emit('close',thos.key_dropdown);
                        }
                    });
                }else{
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            setPriority(number = null){
                this.muParams.priority = number;
                var thos = this;
                setTimeout(function() {
                    thos.save_update_task();
                }, 10);
            }
        },
    };
</script>