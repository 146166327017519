<template>
  <div class="list_tag" v-if="Array.isArray(list_tag)">
    <div class="display-tag" v-for="(item,key) in list_tag.slice(0, 2)" :key="key" :style="{'background':'#'+item.hexa+'33','color':'#'+item.hexa}">
      <!-- <span class="om-tag"></span> -->
      <span class="dis-name">{{item.name}}</span>
    </div>
    <div class="plus_tag" v-if="list_tag.length > 2">+{{list_tag.length-2}}</div>
  </div>
</template>
<script>
  export default {
    props: ['list_tag'],
    data: function() {
      return {
        muListTag: this.list_tag,
      };
    }
  };
</script>