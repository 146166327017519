<template>
    <div class="modal-body">
        <div class="list-priority flex" @click="editCustomField()">
            <span class="om-pencil"></span>
            <span>Rename</span>
        </div>
        <div class="list-priority flex"  @click="deleteCustomField()">
            <span class="om-delete"></span>
            <span>Delete</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
            };
        },
        methods: {
            editCustomField(){
                this.$emit('close',this.key_dropdown);
                this.muParams.isUpdate = true;
                eventBus.$emit('custom-field-add', this.muParams);
            },
            deleteCustomField(){
                var thos = this;
                this.$confirm({
                    title: 'Confirm your action',
                    message: 'Are you sure?',
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            Service.post(this.$site_url+'custom_field/delete',{idCustomField:this.muParams.id}).then((response) => {
                                if(response.status == 'ok'){
                                    eventBus.$emit('update-custom-field');
                                    thos.$emit('close',thos.key_dropdown);
                                }
                            });
                        }
                    }
                })
            }
        },
    };
</script>