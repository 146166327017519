<template>
    <div class="w-100" @focusout="handleFocusOut" ref="ibMessage" tabindex="0">
        <div v-if="showSuggestions" class="list_sugestion_chat" tabindex="0">
            <ul>
                <li v-for="user in filteredUsers" :key="user.id" @click.stop="selectUser(user)">
                    <span class="one-alfabet green" :style="{'background-color':'#'+user.avatar_color,'color':'white'}">{{$filters.firstCharacter(user.name==''?user.email:user.name)}}</span>
                    <span>{{user.name==''?user.email:user.name}}</span>
                </li>
            </ul>
        </div>
        <div class="form-input-comment" @click="openComment()">
            <input type="text" name="" v-model="inputComment.text" placeholder="Write a comment..." v-on:keyup.enter="save_comment" @input="detectTagging">
            <div class="form-input-button">
                <div class="list-button" v-if="onFocus">
                    <div v-if="inputComment.attach != undefined"><span class="om-document"></span> {{inputComment.attach.length}}</div>
                    <GoogleDriveComponent
                    @response="responseGoogleDrive"
                    />
                </div>
                <button :disabled="inputComment.text == ''" @click="save_comment()">Send</button>
            </div>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    import GoogleDriveComponent from '@/components/statis/GoogleDriveComponent.vue';
    export default {
        props: ['data_task','type_comment','list_parent','category'],
        components: {
            GoogleDriveComponent
        },
        data: function() {
            return {
                inputComment:{
                    text:''
                },
                tempAttachments:[],
                listUser:[],
                filteredUsers:[],
                taggedUsers:[],
                showSuggestions: false,
                onFocus:false
            };
        },
        methods: {
            save_comment(){
                if (this.inputComment.text != '') {
                    var thos = this;
                    var dataSend = {
                        idTask: (this.data_task != undefined?this.data_task.code:''),
                        comment:this.inputComment.text,
                        type:this.type_comment,
                        attach: (this.inputComment.attach != undefined?JSON.stringify(this.inputComment.attach):'[]')
                    };
                    if (this.type_comment == 'comment_parent') {
                        dataSend['idParent'] = this.list_parent.id;
                    }

                    if (this.category != undefined) {
                        delete dataSend.idTask;
                        dataSend['category'] = this.category;
                        if (this.category == 'project') {
                            dataSend['idProject'] = this.$route.params.idProject;
                        }
                    }

                    this.checkTagText();

                    if (this.taggedUsers.length > 0) {
                        dataSend['taggedUsers'] = JSON.stringify(this.taggedUsers);
                    }

                    console.log(dataSend);
                    var falase = true;
                    if (falase) {
                        Service.post(thos.$site_url+'comment/add',dataSend).then((response) => {
                            if(response.status == 'ok'){
                                thos.inputComment.text = '';
                                if (thos.type_comment == 'activity') {
                                    thos.list_parent.list.push(response.data);
                                }
                                if (['comment','comment_parent'].includes(thos.type_comment)) {
                                    thos.list_parent.chat.push(response.data);
                                    thos.list_parent.chat_number = parseInt(thos.list_parent.chat_number)+1;
                                }
                                if (['comment_custom'].includes(thos.type_comment)) {
                                    thos.list_parent.push(response.data);
                                }
                                thos.$emit('update-parent');
                                eventBus.$emit('dropdown-resize', {});
                            }
                        });
                    }
                }
            },
            checkTagText(){
                const tags = this.inputComment.text.match(/@\w+/g); // Contoh: ["@Alice", "@Bob"]
                this.taggedUsers = [];
                if (tags) {
                    tags.forEach(tag => {
                        const username = tag.slice(1);
                        const user = this.listUser.find(user => user.username === username);
                        if (user) {
                            this.taggedUsers.push(user.user_id);
                        }
                    });
                }

                console.log('list tag',this.taggedUsers)
            },
            selectUser(user) {
                const words = this.inputComment.text.split(' ');
                words.pop();
                this.inputComment.text = words.join(' ') + ` @${user.username} `;
                this.showSuggestions = false;
            },
            detectTagging(){
                const lastWord = this.inputComment.text.split(' ').pop();
                // Jika pengguna mengetikkan '@', mulai tampilkan saran nama pengguna
                if (lastWord.startsWith('@')) {
                    this.showSuggestions = true;
                    const searchTerm = lastWord.slice(1).toLowerCase();

                    // Filter nama pengguna berdasarkan input setelah '@'
                    this.filteredUsers = this.listUser.filter(user =>
                      user.name.toLowerCase().includes(searchTerm)
                      );
                } else {
                    this.showSuggestions = false;
                }
            },
            openComment(){
                this.onFocus = true;
                eventBus.$emit('dropdown-resize', {});
            },
            responseGoogleDrive(params){
                console.log('select',params);
                if (params.length > 0) {
                    this.inputComment.attach = params;
                }else{
                    delete this.inputComment.attach;
                }
            },
            handleFocusOut(){
                setTimeout(() => {
                    if(!this.$refs.ibMessage.querySelector(':focus')){
                        // this.onFocus = false;
                        // this.showSuggestions = false;
                        eventBus.$emit('dropdown-resize', {});
                    }
                },50);
            },
            getUser(){
                var thos = this;
                eventBus.$emit('get-global-user',{},(response) => {
                    thos.listUser = structuredClone(response);
                    thos.filteredUsers = structuredClone(response);
                });
            },
            openFiles(){
                var redirect_url = location.origin+'/set_gd';
                var your_client_id = this.$client_id;
                var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
                var URL = "https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/drive&response_type=code&access_type=offline&redirect_uri="+redirect_url+"&client_id="+your_client_id;
                // console.log(URL);
                window.open(URL, "_blank", strWindowFeatures);
            },
        },
        mounted() {
            this.getUser();
        }
    };
</script>
