<template>
  <div class="display-priority" v-if="task.priority == null">
    <span class="om-flags"></span>
    <span class="dis-name" v-if="hide_name == undefined"></span>
  </div>
  <div class="display-priority" v-if="task.priority == '1'">
    <span class="om-flags-bold color-low"></span>
    <span class="dis-name" v-if="hide_name == undefined">Low</span>
  </div>
  <div class="display-priority" v-if="task.priority == '2'">
    <span class="om-flags-bold color-normal"></span>
    <span class="dis-name" v-if="hide_name == undefined">Normal</span>
  </div>
  <div class="display-priority" v-if="task.priority == '3'">
    <span class="om-flags-bold color-high"></span>
    <span class="dis-name" v-if="hide_name == undefined">High</span>
  </div>
  <div class="display-priority" v-if="task.priority == '4'">
    <span class="om-flags-bold color-urgent"></span>
    <span class="dis-name" v-if="hide_name == undefined">Urgent</span>
  </div>
</template>
<script>
  export default {
    props: ['task','hide_name'],
    data: function() {
      return {
        muTask: this.task,
      };
    }
  };
</script>