<template>
    <div class="list_activity" v-if="data_activity.field != 'comment'">
        <div>
            <span>(You) {{changedName[data_activity.type]}} {{data_activity.field}}</span>
            <span v-if="data_activity.from != null"> from {{data_activity.from}}</span>
            <span  v-if="data_activity.to != null"> to {{data_activity.to}}</span>
        </div>
        <div>
            <span :title="$filters.formatDate(data_activity.created_date)"> {{$filters.formatFromNow(data_activity.created_date)}}   
            </span>
        </div>
    </div>
    <div v-if="data_activity.field == 'comment'">
        <ActivityCommentComponent
        :data_activity="data_activity"
        :data_user="data_user"
        />
    </div>
</template>
<script>
    import ActivityCommentComponent from '@/components/space/comment/ActivityCommentComponent.vue';
    export default {
        props: ['data_activity','data_user'],
        components: {
            ActivityCommentComponent
        },
        data: function() {
            return {
                changedName:{
                    'new':'created',
                    'set':'set',
                    'update':'changed',
                },
            };
        },
        methods: {
        }
    };
</script>
