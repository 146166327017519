<template>
    <div class="modal-body list_btn_task_act">
        <div class="taskActButton">
            <div class="btn" @click="copyLink()">{{textGen.copyLink}}</div>
            <div class="btn" @click="openTab()">New Tab</div>
        </div>
        <!-- <div class="list-priority flex">
            <span class="om-pencil"></span>
            <span>Rename</span>
        </div> -->
        <div class="list-priority flex" @click="set_archive_task()">
            <span class="om-box"></span>
            <span>Archive</span>
        </div>
        <div class="list-priority flex" @click="set_delete_task()">
            <span class="om-delete color-urgent"></span>
            <span>Delete</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                textGen:{
                    copyLink : 'Copy Link'
                },
                muParams: this.dataParam,
            };
        },
        methods: {
            save_update_task(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'task/update',{idTask:this.muParams.code,priority:this.muParams.priority,type:'priority'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.$emit('close',thos.key_dropdown);
                        }
                    });
                }else{
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            set_delete_task(){
                var thos = this;
                this.$confirm({
                    title: 'Confirm your action',
                    message: 'Are you sure?',
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            Service.post(this.$site_url+'task/delete',{idTask:this.muParams.code}).then((response) => {
                                if(response.status == 'ok'){
                                    thos.$emit('close',thos.key_dropdown);
                                    eventBus.$emit('task-hide', {code:thos.muParams.code});
                                }
                            });
                        }
                    }
                })
            },
            set_archive_task(){
                var thos = this;
                Service.post(this.$site_url+'task/archive',{idTask:this.muParams.code}).then((response) => {
                    if(response.status == 'ok'){
                        thos.$emit('close',thos.key_dropdown);
                        eventBus.$emit('task-hide', {code:thos.muParams.code});
                    }
                });
            },
            copyLink(){
                var comment = window.location.origin+'/1/task/'+this.dataParam.code;
                navigator.clipboard.writeText(comment);
                this.textGen.copyLink = 'Copied!';
                var thos = this;
                setTimeout(function() {
                    thos.textGen.copyLink = 'Copy Link';
                }, 1000);
            },
            openTab(){
                const routeData = this.$router.resolve({ name: "task", params: {idTask:this.dataParam.code,idWorkspace:1}});
                window.open(routeData.href, '_blank');
            }
        },
    };
</script>