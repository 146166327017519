import axios from 'axios';
import eventBus from '@/services/eventBus';
// import $ from 'jquery';
// import '@/assets/js/jquery.cookie.js';

// axios.defaults.withCredentials = true;
const packageVersion = require('../../package.json').version;

export default {
  get(url = '') {
    let config = {
      headers: {
        'version' : packageVersion,
        // 'platform' : 'web_main'
      }
    }
    var dataToken = localStorage.getItem("OToken");
    if (dataToken != undefined && dataToken != 'undefined') {
      config.headers["O-Token"] = dataToken;
    }
    var dataSpaces = localStorage.getItem("idSpace");
    if (dataSpaces != undefined && dataSpaces != 'undefined') {
      config.headers["Id-Space"] = dataSpaces;
    }
    var dataProjects = localStorage.getItem("idProject");
    if (dataProjects != undefined && dataProjects != 'undefined') {
      config.headers["Id-Project"] = dataProjects;
    }
    var dataWorkspaces = localStorage.getItem("idWorkspace");
    if (dataWorkspaces != undefined && dataWorkspaces != 'undefined') {
      config.headers["Id-Workspace"] = dataWorkspaces;
    }

    return axios.get(url,config).then((response) => {
      if (response.data.data.status == "error") {
        eventBus.$emit('toast-on', response.data.data.message ,'warning');
      }
      return response.data;
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          eventBus.$emit('toast-on', error.response.data.message ,'warning');
          // this.errorMessage = 'Terjadi kesalahan: Bad Request. Silakan periksa data yang Anda kirim.';
        } else if (error.response.status === 401){
          // this.errorMessage = 'Terjadi kesalahan: Auth Error';
        }
      } else if (error.request) {
        // this.errorMessage = 'Tidak ada respons dari server.';
      } else {
        // this.errorMessage = 'Kesalahan: ' + error.message;
      }
      return error.response.data;
    });
  },
  just_get(url = '') {
    let config = {
      headers: {
      }
    }
    return axios.get(url,config).then((response) => {
      return response.data;
    });
  },
  post(url = '',data = []){
    let config = {
      headers: {
        'version' : packageVersion,
        // 'platform' : 'web_main'
      }
    }
    var dataToken = localStorage.getItem("OToken");
    if (dataToken != undefined && dataToken != 'undefined') {
      config.headers["O-Token"] = dataToken;
    }
    var dataSpaces = localStorage.getItem("idSpace");
    if (dataSpaces != undefined && dataSpaces != 'undefined') {
      config.headers["Id-Space"] = dataSpaces;
    }
    var dataProjects = localStorage.getItem("idProject");
    if (dataProjects != undefined && dataProjects != 'undefined') {
      config.headers["Id-Project"] = dataProjects;
    }
    var dataWorkspaces = localStorage.getItem("idWorkspace");
    if (dataWorkspaces != undefined && dataWorkspaces != 'undefined') {
      config.headers["Id-Workspace"] = dataWorkspaces;
    }

    var bodyFormData = new FormData();

    // var selectType = localStorage.getItem("selectType");
    // if (selectType != undefined || selectType != null) {
    //   selectType = JSON.parse(selectType);
    //   bodyFormData.append('type_id', selectType.id);
    // }

    for(let i in data){
      if(data[i] != undefined){
        bodyFormData.append(i, data[i]);
      }
    }
    return axios.post(url,bodyFormData,config).then((response) => {
      if (response.data.data.status == "error") {
        eventBus.$emit('toast-on', response.data.data.message ,'warning');
      }
      return response.data;
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          eventBus.$emit('toast-on', error.response.data.message ,'warning');
          // this.errorMessage = 'Terjadi kesalahan: Bad Request. Silakan periksa data yang Anda kirim.';
        } else if (error.response.status === 401){
          // this.errorMessage = 'Terjadi kesalahan: Auth Error';
        }
      } else if (error.request) {
        // this.errorMessage = 'Tidak ada respons dari server.';
      } else {
        // this.errorMessage = 'Kesalahan: ' + error.message;
      }
      return error.response.data;
    });
  },
  post_download(url = '',data = []){
    let config = {
      headers: {
        'version' : packageVersion,
        // 'platform' : 'web_main'
      },
      responseType: 'blob'
    }
    var dataToken = localStorage.getItem("OToken");
    if (dataToken != undefined) {
      config.headers.OToken = dataToken;
    }

    var bodyFormData = new FormData();

    var selectCompany = localStorage.getItem("selectCompany");
    if (selectCompany != undefined || selectCompany != null) {
      selectCompany = JSON.parse(selectCompany);
      bodyFormData.append('company_id', selectCompany.id);
    }

    for(let i in data){
      if(data[i] != undefined){
        bodyFormData.append(i, data[i]);
      }
    }
    return axios.post(url,bodyFormData,config).then((response) => {
      return response.data;
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          eventBus.$emit('toast-on', error.response.data.message ,'warning');
          // this.errorMessage = 'Terjadi kesalahan: Bad Request. Silakan periksa data yang Anda kirim.';
        } else if (error.response.status === 401){
          // this.errorMessage = 'Terjadi kesalahan: Auth Error';
        }
      } else if (error.request) {
        // this.errorMessage = 'Tidak ada respons dari server.';
      } else {
        // this.errorMessage = 'Kesalahan: ' + error.message;
      }
      return error.response.data;
    });
  }
};