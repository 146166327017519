<template>
    <div class="modal-task" v-if="isOpen">
        <div class="modal-space-wrapper">
            <div class="modal-space">
                <div class="space-body">
                    <div class="space-act-close">
                        <h5>{{isUpdate?'Update':'Create'}} a project</h5>
                        <span class="om-close" @click="closeBack()"></span>
                    </div>
                    <div class="space-act" style="margin-top: 20px;">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label>
                            <div class="space-name">
                                <input type="email" class="form-control" id="exampleInputEmail1" v-model="newSpace.name" aria-describedby="emailHelp" placeholder="e.g. Project, Marketing">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-footer">
                    <button :disabled="newSpace.name == ''" @click="createProject()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                isUpdate: false,
                dataSpace: {},
                dataProject: {},
                newSpace: {
                    name:'',
                },
            };
        },
        methods: {
            closeBack(){
                this.isOpen = false;
            },
            createProject(){
                var thos = this;
                if (this.isUpdate) {
                    this.newSpace.idProject = this.dataProject.id;
                    Service.post(this.$site_url+'project/update',this.newSpace).then((response) => {
                        if(response.status == 'ok'){
                            thos.dataProject.name = thos.newSpace.name;
                            thos.closeBack();
                        }
                    });
                }else{
                    Service.post(this.$site_url+'project/add',this.newSpace).then((response) => {
                        if(response.status == 'ok'){
                            this.dataSpace.child.push(response.data);
                            thos.closeBack();
                            eventBus.$emit('toast-on','success add project');
                        }
                    });
                }
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('open-modal-project', function (params) {
                thos.isOpen = true;
                // console.log(params);
                if (params.isUpdate != undefined) {
                    thos.dataProject = params;
                    thos.isUpdate = true;
                    thos.newSpace.name = params.name;
                }else{
                    thos.dataSpace = params;
                    thos.newSpace.idSpace = params.id;
                }
            });

            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-space-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-task', function () {
                thos.closeBack();
            });
        },
    };
</script>
