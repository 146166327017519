<template>
    <draggable class="dragArea" tag="div" :list="list_child" :group="{ name: 'list_task' }" item-key="id" @change="log" @end="handleChange" :data-id="column_data.id" :data-parent="parent_id">
        <template #item="{ element }">
            <div class="list_row">
                <div class="list_column" :key="element.id">
                    <div class="act">
                        <span class="om-home"></span>
                        <input type="checkbox" name="">
                    </div>
                    <div  class="name"  :style="{ 'padding-left': (25*number_child) + 'px' }">
                        <span
                        :class="{ 'om-chev-right': !element.show_child , 'om-chev-down': element.show_child }" 
                        @click="showChild(element)"></span>
                        <div @click="openTask(element)" class="name-selected">
                            <div class="named-left">
                                <div @click.stop="updateStatus($event,element)" class="act_status_btn">
                                    <div class="status_form" >
                                        <span :class="(element.status_id != undefined?checkStatus(element,'icon'):'-')" :style="{ 'color': '#'+(element.status_id != undefined?checkStatus(element,'hexa'):'000') }"></span>
                                    </div>
                                </div>

                                <p class="name-task" :title="element.title">{{element.title}}</p>
                                <p v-if="element.child_number > 0"><span class="om-sub"></span>{{element.child_number}}</p>
                                <TagComponent
                                :list_tag="element.tag"
                                />
                                <div @click.stop="updateTag($event,element)" class="act_mini_btn2" v-if="element.tag.length > 0">
                                    <span class="om-tag"></span>
                                </div>
                            </div>
                            <div class="named-right">
                                <div @click.stop="updateTag($event,element)" class="act_mini_btn2" v-if="element.tag.length == 0">
                                    <span class="om-tag"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="assign">
                        <div @click="updateAssign($event,element)" class="act_list_btn">
                            <div v-for="(item,key) in element.assignee" :key="key">
                                <span  class="one-alfabet two-alfabet" :style="{'background-color':'#'+item.avatar_color,'color':'white'}" >
                                    {{$filters.twoCharacter(item.name==''?item.email:item.name)}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="status">
                        <div @click="updateDueDate($event,element)" class="act_list_btn">
                            {{$filters.formatDate(element.end,'MMM DD')}}
                        </div>
                    </div>
                    <div class="status">
                        <div @click="updatePriority($event,element)" class="act_list_btn">
                            <PriorityComponent
                            :task="element"
                            />
                        </div>
                    </div>
                    <div class="status task_status">
                        <div @click="updateStatus($event,element)" class="act_list_btn">
                            <div class="status_form"  :style="{ 'background-color': '#'+(element.status_id != undefined?checkStatus(element,'hexa'):'000') }"> {{(element.status_id != undefined?checkStatus(element,'name'):'-')}}
                            </div>
                        </div>
                    </div>
                    <div class="status">
                        <div @click="showComment($event,element)" class="act_list_btn">
                            <span class="om-chat" style="margin-right:5px"></span> {{(element.chat_number>0?element.chat_number:'')}}
                        </div>
                    </div>
                    <div class="act_2">
                        <div @click="actTask($event,element)" class="act_list_btn" style="flex: 0 0 30px;">
                            <span class="om-more"></span>
                        </div>
                    </div>
                </div>
                <ProjectComponentList 
                v-if="element.show_child"
                :list_child="element.child" 
                :number_child="number_child+1" 
                :parent_id="element.id"
                :status_data="status_data"
                :column_data="column_data"  />
                <ProjectListAddComponent
                v-if="element.new_task != undefined"
                :data_child="element"
                :number_child="number_child+1" 
                @set_child="onSetChild"
                />
            </div>
        </template>
    </draggable>
</template>
<script>
    import draggable from "vuedraggable";
    import ProjectListAddComponent from '@/components/space/task/ProjectListAddComponent.vue';
    import PriorityComponent from '@/components/statis/PriorityComponent.vue';
    import TagComponent from '@/components/statis/TagComponent.vue';
    import Service from '../../services/Services';
    import eventBus from '../../services/eventBus';
    export default {
        name: 'ProjectComponentList',
        props: {
            list_child: Array,
            number_child: Number,
            column_data: Object,
            parent_id: String,
            status_data: Object,
        },
        components: {
            draggable,
            ProjectListAddComponent,
            PriorityComponent,
            TagComponent
        },
        data: function() {
            return { 
                muListchild: this.list_child,
                muStatusData: this.status_data,
            };
        },
        created: function() {
        },
        unmounted () {
        },
        methods: {
            checkChild(child){
                if (child.length > 0) {
                    child.map(function(v){
                        this.checkChild(v.child)
                    })
                }
                child.map(function(v){
                    delete v.new_task;
                    v.show_child = false;
                })
            },
            showChild(params){
                params.show_child = !params.show_child;
                if (params.child_number > 0 && params.child.length == 0) {
                    Service.post(this.$site_url+'task/get_sub',{parentId:params.code}).then((response) => {
                        if(response.status == 'ok'){
                            params.child = response.data;
                        }
                    });
                }else{
                    if (params.show_child && params.child.length == 0) {
                        setTimeout(function() {
                            params.new_task = {};
                        }, 10);
                    }else{
                        delete params.new_task;
                    }

                    // if (params.child.length > 0 && !params.show_child) {
                    //     this.checkChild(params.child);
                    // }
                }
                console.log('child',params);
            },
            checkStatus(element,type){
                if (type == 'hexa') {
                    if (this.muStatusData[element.status_id] == undefined) {
                        return '000';
                    }else{
                        return this.muStatusData[element.status_id].hexa;
                    }
                }else if (type == 'icon') {
                    if (this.muStatusData[element.status_id] == undefined) {
                        return '';
                    }else{
                        return this.muStatusData[element.status_id].icon;
                    }
                }else{
                    if (this.muStatusData[element.status_id] == undefined) {
                        return '';
                    }else{
                        return this.muStatusData[element.status_id].name;
                    }
                }
            },
            log(event) {
                // console.log(event);
                if (event.added != undefined) {
                    var selectTask = {'id':event.added.element.code,'name':event.added.element.title};
                    eventBus.$emit('project-task-set', selectTask);
                }
            },
            updateAssign(el,params){
                // params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'AssignComponent',
                    data:params
                });
            },
            updateTag(el,params){
                // params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'TagComponent',
                    data:params
                });
            },
            updatePriority(el,params){
                params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'PriorityComponent',
                    data:params
                });
            },
            updateStatus(el,params){
                params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'StatusComponent',
                    data:params
                });
            },
            updateDueDate(el,params){
                params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'DueDateComponent',
                    data:params
                });
            },
            actTask(el,params){
                eventBus.$emit('openDropdown', {target:el,
                    component:'TaskActComponent',
                    data:params
                });
            },
            showComment(el,params){
                params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'CommentComponent',
                    data:params
                });
            },
            openTask(dataTask){
                eventBus.$emit('open-modal-task', {code:dataTask.code});
            },
            handleChange(event){
                // console.log(event.to.getAttribute("data-id"));
                var toColumn = {id: event.to.getAttribute("data-id"),parentId: event.to.getAttribute("data-parent")};
                eventBus.$emit('project-column-set', toColumn);
                // console.log(event,toColumn);
            },
            onSetChild(newChild){
                console.log(newChild);
                // this.muListchild.push(newChild);
            },
        },
        mounted(){
        },
        watch: {
        }
    }
</script>
