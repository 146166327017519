<template>
    <div class="viewActivity viewActivityChat">
        <div class="top-right">
            <div class="act_mini_btn" @click="backActivity()" style="flex: 0 0 auto;">
                <span class="om-chev-left" style="margin-right:5px"></span> Back
            </div>
            <span style="flex: 1;text-align: center;">Custom Field Log</span>
        </div>
        <div class="list_activities">
            <div class="list_activity" v-for="(item,key) in muListLog.log" :key="key">
                <div>
                    <span>(You) {{changedName[item.type]}} {{item.field}}</span>
                    <span v-if="item.from != null"> from {{item.from}}</span>
                    <span  v-if="item.to != null"> to {{item.to}}</span>
                </div>
                <div>
                    <span :title="$filters.formatDate(item.created_date)"> {{$filters.formatFromNow(item.created_date)}}   
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name:"ActivityListCustomField",
        props: {
            data_log: Object
        },
        components: {
        },
        data: function() {
            return {
                muListLog: this.data_log,
                changedName:{
                    'new':'created',
                    'set':'set',
                    'update':'changed',
                },
            };
        },
        methods: {
            updateParent(){
            },
            backActivity(){
                this.$emit('back-activity');
            }
        },
        mounted(){
        },
        watch: {
        },
    };
</script>