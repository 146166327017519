<template>
    <div class="modal-task" v-if="isOpen">
        <div class="modal-space-wrapper">
            <div class="modal-space">
                <div class="space-body">
                    <div class="space-act-close">
                        <h5>Manage teams</h5>
                        <span class="om-close" @click="closeBack()"></span>
                    </div>
                    <button class="btn-done mt-3" @click="openInvite = !openInvite" v-if="!openInvite">Invite Team</button>
                    <div class="border-team" v-if="openInvite">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Invite by Email</label>
                            <div class="space-name">
                                <input type="email" name="email" v-model="newForm.email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="e.g. email@email.com">
                            </div>
                        </div>
                        <button class="btn-close" @click="openInvite = !openInvite">Close</button>
                        <button class="btn-done" @click="doInvite()" :disabled="newForm.email == ''">Invite</button>
                    </div>
                    <div class="listView teamModal">
                        <div class="content_list">
                            <div class="vl_head">
                                <div class="email">Email</div>
                                <div class="name">Name</div>
                                <div class="act_2"><span class="om-home"></span></div>
                            </div>
                            <div class="vl_body">
                                <div class="list_column list_add" v-for="(item,key) in listTeam" :key="key">
                                    <div class="email">{{item.email}}</div>
                                    <div class="name">{{item.name==''?'No Name':item.name}}</div>
                                    <div class="act_2">
                                        <div class="act_mini_btn" v-if="item.role != '3'">
                                            <span class="om-delete" @click="deleteTeam(item)"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-footer">
                    <button @click="closeBack()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                isUpdate: false,
                openInvite: false,
                selectParam:{},
                listTeam: [],
                newForm: {
                    email:'',
                    idWorkspace: '',
                },
            };
        },
        methods: {
            closeBack(){
                this.isOpen = false;
            },
            getListTeams(){
                var thos = this;
                // console.log(params);
                Service.post(this.$site_url+'space/team',{idSpace:this.selectParam.id}).then((response) => {
                    if(response.status == 'ok'){
                        // thos.closeBack();
                        console.log(response);
                        thos.listTeam = response.data;
                        thos.newForm.idWorkspace = response.workspace_id;
                    }
                });
            },
            doInvite(){
                Service.post(this.$site_url+'space/add_team',this.newForm).then((response) => {
                    if(response.status == 'ok'){
                        this.newForm.email = '';
                        this.openInvite = false;
                        this.getListTeams();
                    }
                });
            },
            deleteTeam(param){
                var thos = this;
                this.$confirm({
                    title: 'Confirm your action',
                    message: 'Are you sure?',
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            Service.post(thos.$site_url+'space/remove_team',{id:param.id,idWorkspace:thos.newForm.idWorkspace}).then((response) => {
                                if(response.status == 'ok'){
                                    thos.getListTeams();
                                }
                            });
                        }
                    }
                })
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('open-modal-team', function (params) {
                thos.isOpen = true;
                thos.selectParam = params;
                thos.getListTeams();
                // if (params.isUpdate != undefined) {
                //     thos.dataProject = params;
                //     thos.isUpdate = true;
                //     thos.newSpace.name = params.name;
                // }else{
                //     thos.dataSpace = params;
                //     thos.newSpace.idSpace = params.id;
                // }
            });

            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-space-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-task', function () {
                thos.closeBack();
            });
        },
    };
</script>
