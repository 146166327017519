<template>
  <div v-if="['photo','video'].includes(files.type)" class="file_attach file_preview">
    <iframe width="100px" height="100px" :src="files.preview"></iframe>
    {{files.name}}
  </div>
  <div v-if="!['photo','video'].includes(files.type)" class="file_attach">
    <span class="om-document cursor-pointer"></span> {{files.name}}
  </div>
</template>
<script>
  export default {
    props: {
      files: Object,
    },
  };
</script>