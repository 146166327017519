<template>
    <div class="modal-body">
        <div class="list-priority flex" v-for="(item,key) in listStatus" :key="key" @click="setStatus(item.id)">
            <span class="om-prog-0" :style="{'color':'#'+item.hexa}"></span>
            <span>{{item.name}}</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
                listStatus: [],
            };
        },
        methods: {
            save_update_task(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'task/update',{idTask:this.muParams.code,idStatus:this.muParams.status_id,type:'status'}).then((response) => {
                        if(response.status == 'ok'){
                            // console.log(thos.muParams);
                            thos.$emit('close',thos.key_dropdown);
                            eventBus.$emit('task-move-status', {});
                        }
                    });
                }else{
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            setStatus(number = null){
                this.muParams.status_id = number;
                var thos = this;
                setTimeout(function() {
                    thos.save_update_task();
                }, 10);
            }
        },
        mounted(){
            var dataStatus = localStorage.getItem("dataStatus");
            this.listStatus = JSON.parse(dataStatus);
            console.log('ss',this.listStatus);
        },
    };
</script>