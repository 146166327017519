<template>
    <div class="modal-task" v-if="isOpen">
        <div class="modal-task-wrapper">
            <div class="modal-task-body">
                <TaskView
                :task_code="taskCode"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import TaskView from '@/views/space/TaskView.vue';
    export default {
        components: {
            TaskView
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                taskCode: '',
            };
        },
        methods: {
            closeBack(){
                this.isOpen = false;
                this.$router.go(-1);
                eventBus.$emit('hide-side', false);
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('open-modal-task', function (params) {
                thos.taskCode = params.code;
                thos.isOpen = true;
                thos.$router.push({ name: "task", params: {idTask:params.code,idWorkspace:1}, query: { plan: 'private' }});
                // thos.$router.replace({ name: "task", params: {idTask:params.code,idWorkspace:1}, query: { plan: 'private' }})
            });

            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-task-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-task', function () {
                thos.closeBack();
            });
        },
    };
</script>
