<template>
    <div class="modal-header" v-if="notFilteredItems.length > 0">
        <div class="list-tag-simple">
            <div class="list-tag flex" v-for="(item,key) in notFilteredItems" :key="key" :style="{'background':'#'+item.hexa+'33','color':'#'+item.hexa}" >
                <span class="om-tag"></span>
                <span>{{item.name==''?item.email:item.name}}</span>
                <span class="om-close" :style="{'background':'#'+item.hexa+'33'}" @click="setTag(item)"></span>
            </div>
        </div>
    </div>
    <div class="modal-header list-tag-input">
        <input type="" name="" v-model="newTag.name" placeholder="Search or Create New" v-on:keyup.enter="save_new_tag">
    </div>
    <div class="modal-body">
        <div class="list-tag flex" v-for="(item,key) in filteredItems" :key="key" @click="setTag(item)">
            <div class="lt-left" :style="{'background':'#'+item.hexa+'33','color':'#'+item.hexa}">
                <span class="om-tag"></span>
                <span>{{item.name==''?item.email:item.name}}</span>
            </div>
            <div class="lt-right">
                <!-- <span class="om-more" @click.stop="buttonAct(item)"></span> -->
            </div>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTag: {
                    name: '',
                },
                muParams: this.dataParam,
                listTag: []
            };
        },
        methods: {
            setTag(param){
                var thos = this;

                var getIndex = this.muParams.tag.findIndex(x => x.tag_id === param.tag_id);
                if (getIndex == -1) {
                    Service.post(this.$site_url+'task/tag',{idTask:this.muParams.code,tag_id:param.tag_id,type:'add'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.muParams.tag.push(param);
                        }
                    });
                }else{
                    Service.post(this.$site_url+'task/tag',{idTask:this.muParams.code,tag_id:param.tag_id,type:'remove'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.muParams.tag.splice(getIndex,1);
                        }
                    });
                }
            },
            save_new_tag(){
                var thos = this;
                if (this.newTag.name != '') {
                    var dataIdSpace = localStorage.getItem("idSpace");
                    Service.post(this.$site_url+'space/add_tag',{
                        idTask:this.muParams.code,
                        idSpace:dataIdSpace,
                        name:this.newTag.name
                    }).then((response) => {
                        if(response.status == 'ok'){
                            thos.getListAssign();
                            thos.muParams.tag.push(response.data);
                            thos.newTag.name = '';
                        }
                    });
                }
            },
            buttonAct(param){
                console.log('belum active',param);
            },
            getListAssign(){
                var thos = this;

                var dataIdSpace = localStorage.getItem("idSpace");
                // if (dataIdSpace != undefined) { dataNews.idSpace = dataIdSpace; }
                Service.post(this.$site_url+'space/tag',{idSpace:dataIdSpace}).then((response) => {
                    if(response.status == 'ok'){
                        thos.listTag = response.data;
                        // thos.newForm.idWorkspace = response.workspace_id;
                    }
                });
            }
        },
        computed: {
            filteredItems() {
                return this.listTag.filter(item => {
                    const getIndex = this.muParams.tag.findIndex(x => x.tag_id === item.tag_id);
                    const getName = item.name.toLowerCase().includes(this.newTag.name.toLowerCase());
                    return (getIndex === -1 && getName);
                });
            },
            notFilteredItems() {
                return this.listTag.filter(item => {
                    const getIndex = this.muParams.tag.findIndex(x => x.tag_id === item.tag_id);
                    return getIndex > -1;
                });
            }
        },
        mounted() {
            this.getListAssign();
        },
    };
</script>