<template>
  <span class="om-calendar"></span>
  <span v-if="task.end != undefined" style="text-wrap: nowrap;margin-left: 10px;">{{$filters.formatDate(task.end,'MMM DD')}}</span>
</template>
<script>
  export default {
    props: ['task','hide_name'],
    data: function() {
      return {
        muTask: this.task,
      };
    },
  };
</script>