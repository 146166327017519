<template>
    <div class="viewActivity">
        <div class="top-right">
            <div class="tab_two">
                <h5>Activity</h5>
            </div>
            <div class="tab_two">
                <button>(soon)</button>
            </div>
        </div>
        <div class="list_activities">
            <div v-for="(item,key) in data_activity.list" :key="key">
                <ActivityDataComponent
                :data_user="data_user"
                :data_activity="item"
                v-if="key+1 != data_activity.list.length && show_Activity"
                />
            </div>
            <div class="button_list_activity" @click="show_Activity = !show_Activity" v-if="data_activity.list.length > 1">
                <span :class="{'om-chev-up':show_Activity,'om-chev-down':!show_Activity}"></span>
                <span>{{show_Activity?'Hide':'Show'}}</span>
            </div>
            <div>
                <ActivityDataComponent
                :data_user="data_user"
                :data_activity="last_activity"
                v-if="muListActivity.list.length > 0"
                />
            </div>
        </div>
        <div class="bottom-right">
            <CommentComponent
            :data_task="data_task"
            :list_parent="muListActivity"
            :type_comment="'activity'"
            @update-parent="updateParent"
            />
        </div>
    </div>
</template>
<script>
    import CommentComponent from '@/components/input/CommentComponent.vue';
    import ActivityDataComponent from '@/components/space/task/ActivityDataComponent.vue';
    export default {
        name:"ActivityList",
        props: {
            data_activity: Object,
            data_task: Object,
            data_user: Array
        },
        components: {
            CommentComponent,
            ActivityDataComponent
        },
        data: function() {
            return {
                muListActivity: this.data_activity,
                last_activity:{},
                show_Activity:false,
            };
        },
        methods: {
            updateParent(){
                console.log(this.data_activity,this.muListActivity);
                this.last_activity = (this.data_activity.list.length > 0?this.data_activity.list[this.data_activity.list.length-1]:{});
            }
        },
        mounted(){
            this.updateParent();
        },
        watch: {
            'data_activity.list'(){
                var thos = this;
                setTimeout(function() {
                    thos.last_activity = (thos.data_activity.list.length > 0?thos.data_activity.list[thos.data_activity.list.length-1]:{});
                }, 10);
            }
        },
    };
</script>