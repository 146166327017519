<template>
    <div class="modal-body dropdown-calendar">
        <div>
            <span class="om-close cursor-pointer" @click="closeComponent()"></span>
            <input placeholder="Task Name" v-model="newTask.title">
        </div>
        <div class="calendar-button">
            <div @click="updatePriority($event,newTask)" class="act_mini_btn act-priority">
                <PriorityComponent
                :task="newTask"
                :hide_name="newTask"
                />
            </div>
            <div @click="updateDueDate($event,newTask)" class="act_mini_btn">
                <DueDateComponent
                :task="newTask"
                :hide_name="newTask"
                />
            </div>
            <button class="btn btn-outline-secondary" :disabled="newTask.title == ''" @click="save_new_task()">Save</button>
        </div>
    </div>
</template>
<script>
    import PriorityComponent from '@/components/statis/PriorityComponent.vue';
    import DueDateComponent from '@/components/statis/DueDateComponent.vue';
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        components: {
            PriorityComponent,
            DueDateComponent
        },
        data: function() {
            return {
                newTask: {
                    title: ''
                },
                test: true,
            };
        },
        methods: {
            save_new_task(){
                var thos = this;
                var dataNews = structuredClone(this.newTask);
                dataNews.end = this.$filters.formatDate(dataNews.end,'YYYY-MM-DD');
                if (this.test) {
                    Service.post(this.$site_url+'task/add',dataNews).then((response) => {
                        if(response.status == 'ok'){
                            thos.newTask.title = '';
                            eventBus.$emit('add-event-calendar', {
                                data:response.data
                            });
                            thos.$emit('close',thos.key_dropdown);
                        }
                    });
                }
            },
            closeComponent(){
                this.$emit('close',this.key_dropdown);
            },
            updatePriority(el,params){
                // params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'PriorityComponent',
                    data:params
                });
            },
            updateDueDate(el,params){
                eventBus.$emit('openDropdown', {target:el,
                    component:'DueDateComponent',
                    data:params
                });
            }
        },
    };
</script>