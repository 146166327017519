<template>
    <div class="add_form_custom_field">
        <div class="modal-body">
            <div class="space-act">
                <div class="form-group">
                    <label for="exampleInputEmail1">Field Name</label>
                    <div class="space-name">
                        <input type="text" class="form-control" v-model="newCustomField.name" placeholder="Enter Name">
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Description (Optional)</label>
                    <div class="space-name">
                        <input type="text" class="form-control" v-model="newCustomField.description" placeholder="Enter Description">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" v-if="muParams.isUpdate == undefined">
            <div class="form-group" style="width: 100%;">
                <label for="exampleInputEmail1">Type</label>
                <div class="space-name">
                    <select name="type_field" class="form-control" v-model="newCustomField.type" @change="checkTypeField()">
                        <option v-for="(item,key) in listCustomField" :key="key" :value="item.type_field"><span :class="item.icon"></span>{{item.type_field}}</option>
                    </select>
                </div>
            </div>
            <div style="width: 100%;" v-if="showMultiple">
                <div class="form-group group_multiple">
                    <label for="exampleInputEmail1">Options</label>
                    <div class="space-name" v-for="(item,key) in listMultiple" :key="key">
                        <input type="text" class="form-control" placeholder="Enter Option" v-model="item.name">
                        <span class="om-close" :class="{'cursor-pointer': listMultiple.length > 1}" @click="removeMultiple(key)"></span>
                    </div>
                    <div class="act_mini_btn" @click="addMultiple()">
                        <button>Add Option</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer footer-act">
            <button class="cancel" @click="closeModal()">Cancel</button>
            <button class="create" :disabled="newCustomField.name == ''" @click="createCustomField()">{{muParams.isUpdate == undefined?'Create':'Update'}}</button>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newCustomField: {
                    name: (this.dataParam.name != null ? this.dataParam.name:''),
                    description: (this.dataParam.description != null ? this.dataParam.description:''),
                    type: this.dataParam.type_field,
                },
                listMultiple: [],
                showMultiple: false,
                muParams: this.dataParam,
                listCustomField: this.dataParam.listCustomField
            };
        },
        methods: {
            createCustomField(){
                var thos = this;
                if (this.newCustomField.type == 'dropdown') {
                    this.newCustomField.multiple = JSON.stringify(this.listMultiple);
                }
                if (this.dataParam.idTask != undefined) {
                    this.newCustomField.idTask = this.dataParam.idTask;
                }
                if (this.dataParam.idProject != undefined) {
                    this.newCustomField.idProject = this.dataParam.idProject;
                }
                if (this.dataParam.isUpdate == undefined) {
                    Service.post(this.$site_url+'custom_field/add',this.newCustomField).then((response) => {
                        if(response.status == 'ok'){
                            if (thos.dataParam.isForm == undefined) {
                                eventBus.$emit('task-update-custom-field',response.data);
                                thos.$emit('close',thos.key_dropdown);
                                thos.$emit('close',parseInt(thos.key_dropdown)-1);
                            }else{
                                thos.$emit('update_data',response.data);
                            }
                        }
                    });
                }else{
                    this.newCustomField.idCustomField = this.dataParam.id;
                    Service.post(this.$site_url+'custom_field/update_field',this.newCustomField).then((response) => {
                        if(response.status == 'ok'){
                            thos.dataParam.name = thos.newCustomField.name;
                            thos.dataParam.description = thos.newCustomField.description;
                            thos.$emit('update_data',response.data);
                        }
                    });
                }
            },
            checkTypeField(){
                this.listMultiple = [];
                this.showMultiple = false;
                if (this.newCustomField.type == 'dropdown') {
                    this.showMultiple = true;
                    this.listMultiple.push({name:''});
                }
                eventBus.$emit('dropdown-resize', {});
            },
            removeMultiple(key){
                if (this.listMultiple.length > 1) {
                    this.listMultiple.splice(key, 1);
                }
            },
            closeModal(){
                this.$emit('close',this.key_dropdown);
            },
            addMultiple(){
                this.listMultiple.push({name:''});
                eventBus.$emit('dropdown-resize', {});
            }
        }
    };
</script>