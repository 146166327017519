<template>
    <div class="modal-body">
        <div class="list-priority flex" v-for="(item,key) in listCustomField" :key="key" @click="selectAction($event,item)">
            <span :class="item.icon"></span>
            <span>{{item.type_field}}</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {},
                muParams: this.dataParam,
                listCustomField: [],
            };
        },
        methods: {
            save_update_task(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'task/update',{idTask:this.muParams.code,priority:this.muParams.priority,type:'priority'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.$emit('close',thos.key_dropdown);
                        }
                    });
                }else{
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            setPriority(number = null){
                this.muParams.priority = number;
                var thos = this;
                setTimeout(function() {
                    thos.save_update_task();
                }, 10);
            },
            selectAction(el,params){
                var thos = this;
                if (this.dataParam.isModalCustomField != undefined) {
                    thos.$emit('close',thos.key_dropdown);
                    eventBus.$emit('custom-field-add', params);
                }else{
                    params.listCustomField = this.listCustomField;
                    params.idTask = this.muParams.code;
                    eventBus.$emit('openDropdown', {target:el,
                        component:'AddFormCfComponent',
                        data:params
                    });
                }
            },
            getListCf()
            {
                Service.post(this.$site_url+'custom_field/base',{}).then((response) => {
                    if(response.status == 'ok'){
                        this.listCustomField = response.data;
                        setTimeout(function() {
                            eventBus.$emit('dropdown-resize', {});
                        }, 100);
                    }
                });
            }
        },
        mounted() {
            this.getListCf();
        }
    };
</script>