<template>
    <div class="modal-body modal-workspace">
        <div class="list-workspace flex">
            <div class="ls-left">
                <span class="one-alfabet green">{{"o"}}</span>
            </div>
            <div class="ls-right">
                <p>{{selectedWorkspace.name}}'s Workspace</p>
                <p>Free</p>
            </div>
        </div>
        <hr>
        <p class="switch">Switch Workspaces</p>
        <div class="list-workspace flex can-select" @click="selectWorkspace(item)" v-for="(item,key) in filteredUsers" :key="key">
            <div class="ls-left">
                <span class="one-alfabet green">{{"o"}}</span>
            </div>
            <div class="ls-right">
                <p>{{item.name}}'s Workspace</p>
                <p>Free</p>
            </div>
        </div>
    </div>
</template>
<script>
    // import Service from '@/services/Services';
    // import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
                idWorkspace: '',
                selectedWorkspace: {}
            };
        },
        computed: {
            filteredUsers() {
                return this.muParams.filter(workspace => workspace.id !== this.idWorkspace);
            }
        },
        methods: {
            selectWorkspace(item){
                localStorage.setItem("idWorkspace", item.id);
                this.$router.push({name: 'home', params: {idWorkspace:item.id}});
                location.reload();
            },
            checkWorkspace(){
                var getIdWorkspace = localStorage.getItem("idWorkspace");
                this.idWorkspace = getIdWorkspace;
                this.muParams.map((v)=>{
                    if(v.id == getIdWorkspace){
                        this.selectedWorkspace = v;
                    }
                })
            }
        },
        mounted(){
            this.checkWorkspace()
        }
    };
</script>