<template>
    <div class="modal-task" v-if="isOpen">
        <div class="modal-task-wrapper">
            <div class="modal-task-body">
                <CustomFieldComponent
                :list_custom_field="listCustomField"
                :data_project="dataProject"
                :data_custom_field="dataCustomField"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    import CustomFieldComponent from '@/components/space/custom_field/CustomFieldComponent.vue';
    export default {
        components: {
            CustomFieldComponent
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                dataProject: {},
                dataCustomField: [],
                listCustomField: [],
            };
        },
        methods: {
            closeBack(){
                this.isOpen = false;
                // this.$router.go(-1);
                // eventBus.$emit('hide-side', false);
            },
            getlistField(){
                var thos = this;
                Service.post(this.$site_url+'project/custom_field',{idProject: this.dataProject.id}).then((response) => {
                    if(response.status == 'ok'){
                        thos.listCustomField = response.data;
                        thos.dataCustomField = response.base;
                        // thos.closeBack();
                    }
                });
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('open-modal-custom-field', function (params) {
                // thos.taskCode = params.code;
                thos.dataProject = params;
                thos.getlistField();
                thos.isOpen = true;
            });

            eventBus.$on('update-custom-field', function () {
                thos.getlistField();
            });

            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-task-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-custom-field', function () {
                thos.closeBack();
            });
        },
    };
</script>
