<template>
    <div v-if="data_activity != undefined">
        <div class="board_comment" v-if="muDataActvity.comment != undefined">
            <div class="b_comment_top">
                <div class="b_comment_user">
                    <div class="left_column">
                        <span class="one-alfabet green round">{{$filters.firstCharacter(muDataActvity.comment.name)}}</span>
                        {{muDataActvity.comment.name}}
                    </div>
                    <div class="right_column">
                        {{$filters.formatFromNow(muDataActvity.comment.created_date)}}
                    </div>
                </div>
                <div class="text_comment" v-html="formatMessage(muDataActvity.comment.content)"></div>
                <div class="files_comment" v-if="muDataActvity.comment.files != undefined">
                    <div v-for="(item,key) in muDataActvity.comment.files" :key="key" @click="openPreview(item)">
                        <GoogleDriveFilesComponent
                        :files="item"
                        />
                    </div>
                </div>
            </div>
            <div class="b_comment_bottom">
                <div class="b_comment_reply">
                    <div class="left_column">
                        <span class="om-home"></span>
                    </div>
                    <div class="right_column">
                        <div class="act_mini_btn">
                            <button @click="openReplyActivity()" class="btn_reply">
                                <span class="comment_no_reply" v-if="muDataActvity.comment.chat_number > 0">{{muDataActvity.comment.chat_number}}</span> 
                                Reply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="board_comment" v-if="muDataActvity.comment == undefined">
            <div class="b_comment_top">
                <div class="b_comment_user">
                    <div class="left_column">
                        <div class="skeleton skeleton-text" style="width:25px;height: 25px;margin-right: 10px;border-radius: 50%;"></div>
                        <div class="skeleton skeleton-text" style="width:60px"></div>
                    </div>
                    <div class="right_column">
                        <div class="skeleton skeleton-text" style="width:60px"></div>
                    </div>
                </div>
                <div>
                    <div class="skeleton skeleton-text" style="width:60px"></div>
                </div>
            </div>
            <div class="b_comment_bottom">
                <div class="b_comment_reply">
                    <div class="left_column">
                        <div class="skeleton skeleton-text" style="width:30px"></div>
                    </div>
                    <div class="right_column">
                        <div class="skeleton skeleton-text" style="width:60px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="data_comment != undefined">
        <div class="board_comment" v-if="muDataComment != undefined">
            <div class="b_comment_top">
                <div class="b_comment_user">
                    <div class="left_column">
                        <span class="one-alfabet green round">{{$filters.firstCharacter(muDataComment.name)}}</span>
                        {{muDataComment.name}}
                    </div>
                    <div class="right_column">
                        {{$filters.formatFromNow(muDataComment.created_date)}}
                    </div>
                </div>
                <div class="text_comment" v-html="formatMessage(muDataComment.content)"></div>
                <div class="files_comment" v-if="muDataComment.files != undefined">
                    <div v-for="(item,key) in muDataComment.files" :key="key" @click="openPreview(item)">
                        <GoogleDriveFilesComponent
                        :files="item"
                        />
                    </div>
                </div>
                <div class="like_comment" v-if="muDataComment.show_child && muDataComment.show_child != undefined">
                    <span class="om-home"></span>
                </div>
            </div>
            <div class="b_comment_bottom" v-if="muDataComment.show_child == false || muDataComment.show_child == undefined">
                <div class="b_comment_reply">
                    <div class="left_column">
                        <span class="om-home"></span>
                    </div>
                    <div class="right_column" v-if="no_reply == undefined">
                        <div class="act_mini_btn">
                            <button @click="openReplay()" class="btn_reply">
                                <span class="comment_no_reply" v-if="muDataComment.chat_number > 0">{{muDataComment.chat_number}}</span> 
                                Reply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="b_comment_bottom" v-if="muDataComment.show_child && muDataComment.show_child != undefined">
                <div class="list_comment">
                    <ActivityCommentComponent
                    :data_comment="item"
                    :no_reply="'hide'"
                    v-for="(item,key) in muDataComment.chat" :key="key"
                    />
                </div>
                <div class="b_comment_list">
                    <CommentComponent
                    :data_task="data_task"
                    :list_parent="muDataComment"
                    :type_comment="'comment_parent'"
                    @update-parent="updateParent"
                    />
                </div>
            </div>
            <div class="b_comment_collapse" v-if="muDataComment.show_child && muDataComment.show_child != undefined" @click="closeReply()">
                Collapse
            </div>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    import CommentComponent from '@/components/input/CommentComponent.vue';
    import GoogleDriveFilesComponent from '@/components/statis/GoogleDriveFilesComponent.vue';
    export default {
        props: ['data_activity','data_comment','data_task','no_reply','data_user'],
        name: 'ActivityCommentComponent',
        components: {
            CommentComponent,
            GoogleDriveFilesComponent
        },
        data: function() {
            return {
                muDataActvity:this.data_activity,
                muDataComment:this.data_comment,
            };
        },
        created: function() {
        },
        unmounted () {
        },
        methods: {
            getDataCommentActivity(){
                // console.log('getting_data',this.data_activity);
                Service.post(this.$site_url+'comment/data',{idComment:this.muDataActvity.comment_id}).then((response) => {
                    this.muDataActvity.comment = response.data;
                });
            },
            openReplay(){
                this.muDataComment.show_child = true;
                if (this.muDataComment.chat.length != this.muDataComment.chat_number) {
                    var thos = this;
                    Service.post(this.$site_url+'comment/get_chat_child',{commentId:this.muDataComment.id}).then((response) => {
                        if(response.status == 'ok'){
                            thos.muDataComment.chat = response.data;
                            // console.log(response);
                            eventBus.$emit('dropdown-resize', {});
                        }
                    });
                }
            },
            openPreview(item){
                eventBus.$emit('open-modal-preview', item);
            },
            closeReply(){
                this.muDataComment.show_child = false;
            },
            updateParent(){

            },
            openReplyActivity(){
                eventBus.$emit('taskView-reply', this.data_activity);
            },
            formatMessage(message){
                const regex = /@(\w+)/g;
                return message.replace(regex, (match, username) => {
                    if (this.data_user != undefined) {
                        const user = this.data_user.find(user => user.username === username);
                        if (user) {
                            return `<a to="/profile/${user.id}" class="tagged-user">@${username}</a>`;
                        }
                    }
                    return match;
                });
            }
        },
        mounted(){
            if (this.data_activity != undefined) {
                if (this.data_activity.comment == undefined) {
                    this.getDataCommentActivity();
                }else{
                    this.muDataActvity = this.data_activity;
                }
            }
        },
        watch: {
            'data_activity'(){
                if (this.data_activity != undefined) {
                    if (this.data_activity.comment == undefined) {
                        this.muDataActvity = this.data_activity;
                        this.getDataCommentActivity();
                    }else{
                        this.muDataActvity = this.data_activity;
                    }
                }
            }
        }
    }
</script>
