<template>
    <div class="modal-body dropdown-comment">
        <div class="content">
            <div class="content-comment">
                <div class="list_comment">
                    <ActivityCommentComponent
                    :data_task="dataParam"
                    :data_comment="item"
                    v-for="(item,key) in dataParam.chat" :key="key"
                    />
                </div>
                <CommentComponent
                :data_task="dataParam"
                :list_parent="dataParam"
                :type_comment="'comment'"
                @update-parent="updateParent"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import ActivityCommentComponent from '@/components/space/comment/ActivityCommentComponent.vue';
    import CommentComponent from '@/components/input/CommentComponent.vue';
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
            };
        },
        components: {
            ActivityCommentComponent,
            CommentComponent
        },
        methods: {
            getLoadChat(){
                var thos = this;
                Service.post(this.$site_url+'comment/get_chat',{taskId:this.dataParam.code}).then((response) => {
                    if(response.status == 'ok'){
                        thos.dataParam.chat = response.data;
                        eventBus.$emit('dropdown-resize', {});
                    }
                });
            },
            updateParent(){

            }
        },
        mounted(){
            if (this.dataParam.chat.length != this.dataParam.chat_number) {
                this.getLoadChat();
            }
        },
    };
</script>