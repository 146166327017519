<template>
    <div class="modal-body">
        <p style="text-align: center">Select An Option</p>
        <div class="list-priority flex" v-for="(item,key) in muParams.value.multiple" :key="key" @click="setPriority(item)">
            <span>{{item.name}}</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
            };
        },
        methods: {
            save_update_task(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'custom_field/update',{idCustomField:this.muParams.id,idTask:this.muParams.idTask,value:this.muParams.value.value,type:this.muParams.type_field}).then((response) => {
                        if(response.status == 'ok'){
                            thos.$emit('close',thos.key_dropdown);
                            this.muParams.log = null;
                        }
                    });
                }else{
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            setPriority(value_dropdown = null){
                this.muParams.value.value = value_dropdown.name;
                var thos = this;
                setTimeout(function() {
                    thos.save_update_task();
                }, 10);
            }
        },
        mounted() {
            console.log(this.dataParam);
        }
    };
</script>