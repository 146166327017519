<template>
    <div class="modal-task" v-if="isOpen">
        <div class="modal-space-wrapper">
            <div class="modal-space">
                <div class="space-body">
                    <div class="space-act-close">
                        <h5>{{isUpdate?'Update':'Create'}} a Space</h5>
                        <span class="om-close" @click="closeBack()"></span>
                    </div>
                    <div class="space-act">
                        <div class="desc">
                            A Space represents teams, departments, or groups, each with its own Lists, workflows, and settings.
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Icon & Name</label>
                            <div class="space-name">
                                <span class="one-alfabet green">{{newSpace.name == ''?'P':$filters.firstCharacter(newSpace.name)}}</span>
                                <input type="email" class="form-control" id="exampleInputEmail1" v-model="newSpace.name" aria-describedby="emailHelp" placeholder="e.g. Project, Marketing">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-footer">
                    <button :disabled="newSpace.name == ''" @click="createSpace()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                isUpdate: false,
                dataSpace:{},
                newSpace: {
                    name:'',
                },
            };
        },
        methods: {
            closeBack(){
                this.isOpen = false;
            },
            createSpace(){
                var thos = this;
                if (this.isUpdate) {
                    this.newSpace.idSpace = this.dataSpace.id;
                    Service.post(this.$site_url+'space/update',this.newSpace).then((response) => {
                        if(response.status == 'ok'){
                            thos.dataSpace.name = thos.newSpace.name;
                            thos.closeBack();
                        }
                    });
                }else{
                    Service.post(this.$site_url+'space/add',this.newSpace).then((response) => {
                        if(response.status == 'ok'){
                            eventBus.$emit('space-get',response.data);
                            thos.closeBack();
                            eventBus.$emit('toast-on','success add space');
                        }
                    });
                }
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('open-modal-space', function (params) {
                thos.isOpen = true;
                if (params != undefined && params.isUpdate != undefined) {
                    thos.dataSpace = params;
                    thos.isUpdate = true;
                    thos.newSpace.name = params.name;
                }
            });

            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-space-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-task', function () {
                thos.closeBack();
            });
        },
    };
</script>
