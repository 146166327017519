<template>
    <div v-for="(item,key) in dataCustomField" :key="key">
        <div class="flex">
            <div class="title">
                <div class="container_custom_field name">
                    <span :class="item.icon"></span>
                    <span class="naming">{{item.name}}</span>
                    <span class="om-info hidden-hover" @click="showLog($event,item)" title="log costum field"></span>
                </div>
            </div>
            <div class="value cursor-pointer" v-if="item.type_field == 'date'" @click="updateDateCF($event,item)">
                <div class="container_custom_field">
                    {{item.value}}
                </div>
            </div>
            <div class="value cursor-pointer" v-if="item.type_field == 'text'"  @click="updateTextCF($event,item)">
                <div class="container_custom_field pre-line">
                    {{item.value}}
                </div>
            </div>
            <div class="value cursor-pointer" v-if="item.type_field == 'checkbox'">
                <div class="container_custom_field">
                    <input type="checkbox" name="" v-model="item.value" @change="updateCheckbox(item)">
                </div>
            </div>
            <div class="value cursor-pointer" v-if="item.type_field == 'dropdown'" @click="updateDropdownCF($event,item)">
                <div class="container_custom_field">
                    {{item.value.value}}
                </div>
            </div>
            <div class="value" v-if="item.type_field == 'file'">
                <div class="container_custom_field list_file">
                    <div v-for="(item,key) in item.value.files" :key="key" @click="openPreview(item)">
                        <GoogleDriveFilesComponent
                        :files="item"
                        />
                    </div>
                </div>
                <div @click="selectFiles(item)">
                    <GoogleDriveComponent
                    @response="responseGoogleDrive"
                    />
                </div>
            </div>
            <div class="value cursor-pointer" v-if="item.type_field == 'number'">
                <div class="container_custom_field field_number">
                    <input type="text" v-model="item.value" @keypress="isNumber($event)" v-on:blur="handleBlurNumber(item)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    import GoogleDriveComponent from '@/components/statis/GoogleDriveComponent.vue';
    import GoogleDriveFilesComponent from '@/components/statis/GoogleDriveFilesComponent.vue';
    export default {
        components: {
            GoogleDriveComponent,
            GoogleDriveFilesComponent
        },
        props: {
            dataCustomField: Array,
            idTask: String
        },
        data() {
            return {
                listDropdown:[],
                selectFile:null,
                isOpen: false,
                left: 0,
                top: 0,
                content: '',
            };
        },
        methods: {
            nextSetting(){
            },
            closeDropdown(idx){
                this.listDropdown.splice(idx, 1);
                this.setGlobal();
            },
            selectFiles(item){
                this.selectFile = item;
            },
            openPreview(item){
                eventBus.$emit('open-modal-preview', item);
            },
            updateDateCF(el,params){
                params.canUpdate = true;
                params.idTask = this.idTask;
                eventBus.$emit('openDropdown', {target:el,
                    component:'CFtanggalComponent',
                    data:params
                });
            },
            updateTextCF(el,params){
                params.canUpdate = true;
                params.idTask = this.idTask;
                eventBus.$emit('openDropdown', {target:el,
                    component:'CFtextComponent',
                    data:params
                });
            },
            updateDropdownCF(el,params){
                params.canUpdate = true;
                params.idTask = this.idTask;
                eventBus.$emit('openDropdown', {target:el,
                    component:'CFdropdownComponent',
                    data:params
                });
            },
            showLog(el,params){
                if (params.log == null) {
                    Service.post(this.$site_url+'custom_field/log',{idCustomField:params.id,idTask:this.idTask,}).then((response) => {
                        if (response.status == 'ok') {
                            params.log = response.data;
                        }
                        this.$emit('select-log',params);
                    });
                }else{
                    this.$emit('select-log',params);
                }
            },
            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            updateCheckbox(item) {
                Service.post(this.$site_url+'custom_field/update',{idCustomField:item.id,idTask:this.idTask,value:item.value,type:item.type_field}).then(() => {
                    item.log = null;
                });
            },
            handleBlurNumber(item){
                Service.post(this.$site_url+'custom_field/update',{idCustomField:item.id,idTask:this.idTask,value:item.value,type:item.type_field}).then(() => {
                    item.log = null;
                });
            },
            responseGoogleDrive(params){
                console.log('select',params);
                if (params.length > 0) {
                    var files = (params != undefined?JSON.stringify(params):'[]');
                    Service.post(this.$site_url+'custom_field/files',{idCustomField:this.selectFile.id,idTask:this.idTask,attach:files,type:this.selectFile.type_field}).then(() => {
                        // item.log = null;
                    });
                }
            },
        },
        mounted() {
        },
    };
</script>