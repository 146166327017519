<template>
    <div class="tab_two tab_breadcrumb" v-if="bread_crumb != undefined">
        <div class="tab_act" v-if="bread_crumb.space != undefined">
            <button @click="routeApp(bread_crumb.space,'space',bread_crumb.space.id)" :class="{'not_active':bread_crumb.space.not_active != undefined}">
                <span class="one-alfabet green">{{$filters.firstCharacter(bread_crumb.space.name)}}</span>
                {{bread_crumb.space.name}}
            </button>
        </div>
        <div class="tab_slash" v-if="bread_crumb.project != undefined">
            /
        </div>
        <div class="tab_act" v-if="bread_crumb.project != undefined">
            <button @click="routeApp(bread_crumb.project,'project_list',bread_crumb.space.id,bread_crumb.project.id)" :class="{'not_active':bread_crumb.project.not_active != undefined}">
                <span class="om-list"></span>
                {{bread_crumb.project.name}}
            </button>
        </div>
    </div>
</template>
<script>
    import eventBus from '@/services/eventBus';
    export default {
        name: 'BreadCrumb',
        props: ['bread_crumb'],
        components: {
        },
        data: function() {
            return {
                idWorkspace:'',
                idProject:'',
                idSpace:'',
                activeTabTwo:''
            };
        },
        created: function() {
        },
        unmounted () {
        },
        methods: {
            routeApp(data,name = '', idSpace = '', idProject = ''){
                if (data.not_active == undefined) {
                    eventBus.$emit('select-route-left',name,idSpace,idProject);
                }
            }
        },
        mounted(){
            this.idWorkspace = this.$route.params.idWorkspace;
            this.idProject = this.$route.params.idProject;
            // console.log(this.$route);
            this.activeTabTwo = this.$route.name;
        },
        watch: {
        }
    }
</script>
