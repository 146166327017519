<template>
    <div class="top-right">
        <div class="tab_two" style="padding-left: unset;">
            <button><span class="om-grid" @click="showTabLeft = !showTabLeft"></span></button>
            <b>Custom Field Manager</b>
        </div>
        <div class="tab_two">
            <!-- <button ><span class="om-more"></span></button> -->
            <button @click="closeModal()"><span class="om-close"></span></button>
        </div>
    </div>
    <div class="taskView customFieldView">
        <div class="tab-left" v-if="showTabLeft"></div>
        <div class="tab-content">
            <div class="mask-body" v-if="settingTabRight.show"></div>
            <div class="body">
                <h1 class="taskViewHead">
                    {{data_project.name}} 
                    <div @click="showActionCustomField($event,muDataProject)" class="act_mini_btn" v-if="!settingTabRight.show">
                        <span class="om-circle-add"></span>
                    </div>
                </h1>
                <div class="listView">
                    <div class="content_list">
                        <div class="vl_head">
                            <div class="name">Name</div>
                            <div class="status">Location</div>
                            <div class="status">Created By</div>
                            <div class="status">Date Created</div>
                            <div class="act_2"><span class="om-home"></span></div>
                        </div>
                        <div class="vl_body">
                            <div class="list_row">
                                <div class="list_column" v-for="(item,key) in muListCustomField" :key="key">
                                    <div class="name">
                                        <span :class="item.icon"></span>
                                        {{item.name}}
                                    </div>
                                    <div class="status">{{data_project.name}}</div>
                                    <div class="status">-</div>
                                    <div class="status">{{$filters.formatDate(item.created_date,'MM/DD/YY')}}</div>
                                    <div class="act_2">
                                        <div class="act_list_btn" style="flex: 0 0 30px;" @click="actCustomField($event,item)">
                                            <span class="om-more"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-right" v-if="settingTabRight.show">
            <AddFormCfComponent
            :dataParam="dataCustomField"
            @close="closeRight"
            @update_data="updateData"
            />
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import AddFormCfComponent from '@/components/space/dropdown/AddFormCfComponent.vue';
    // import Service from '@/services/Services';
    export default {
        name: 'TaskView',
        props: ['data_project','list_custom_field','data_custom_field'],
        components: {
            AddFormCfComponent,
        },
        data: function() {
            return {
                showTabLeft: false,
                muDataProject: this.data_project,
                dataCustomField: {},
                settingTabRight:{
                    show:false,
                    loadFirst: 'activity',
                    tabActive: 'activity',
                    selectMenu: 'activity'
                },
                listStatus: {},
                muListCustomField: this.list_custom_field
            };
        },
        created: function() {
        },
        unmounted () {
        },
        methods: {
            closeModal(){
                eventBus.$emit('close-modal-custom-field', {});
            },
            showActionCustomField(el,params){
                params.isModalCustomField = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'ActionAddCfComponent',
                    data:params
                });
            },
            closeRight(){
                this.settingTabRight.show = false;
            },
            updateData(data){
                this.muListCustomField = data;
                this.settingTabRight.show = false;
            },
            actCustomField(el,params){
                // params.isModalCustomField = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'CustomFieldActComponent',
                    data:params
                });
            },
        },
        mounted(){
            var thos = this;
            eventBus.$on('custom-field-add', function (params) {
                thos.settingTabRight.show = true;
                thos.dataCustomField = params;
                thos.dataCustomField.isForm = true;
                thos.dataCustomField.idProject = thos.data_project.id;
                thos.dataCustomField.listCustomField = thos.data_custom_field;
            });

            eventBus.$on('custom-field-add-close', function () {
                // thos.settingTabRight.show = false;
            });
        },
        watch: {
            'list_custom_field'(){
                this.muListCustomField = this.list_custom_field
            }
        }
    }
</script>