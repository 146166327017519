<template>
    <div class="modal-body">
        <textarea v-model="muParams.value" style="width: 300px;" v-on:blur="save_update_field" @input="resize()" ref="textarea"></textarea>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        components: {
        },
        data: function() {
            return {
                newDate: [],
                muParams: this.dataParam,
            };
        },
        methods: {
            save_update_field(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'custom_field/update',{idCustomField:this.muParams.id,idTask:this.muParams.idTask,value:this.muParams.value,type:this.muParams.type_field}).then((response) => {
                        if(response.status == 'ok'){
                            thos.$emit('close',thos.key_dropdown);
                            this.muParams.log = null;
                        }
                    });
                }else{
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            resize() {
                let element = this.$refs["textarea"];
                element.style.height = "18px";
                element.style.height = element.scrollHeight + "px";
                if (element.scrollHeight > 0) {
                    eventBus.$emit('dropdown-resize');
                }
            }
        },
        mounted(){
            this.resize();
        },
    };
</script>